import React from 'react';
import PropTypes from 'prop-types';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Container from '~components/Container';

import Carousel from './components/AboutCarousel';

const useStyles = makeStyles(() => ({
  testimonialTitle: {
    fontWeight: '500',
    fontSize: '36px',
    lineHeight: '30px',
    color: '#fff',
    marginBottom: '25px'
  },
  testimonialSubtitle: {
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '400',
    color: '#fff',
    marginBottom: '30px',
    opacity: '0.87'
  },
  testimonialUser: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  testimonialAvatar: {
    display: 'flex !important',
    marginRight: '15px',
    width: '40px',
    height: '40px',
    borderRadius: '50%'
  },
  carouselOutline: {
    outline: 'none'
  }
}));

const AboutTestimonials = ({ testimonials_background_color = '#808aa8', testimonials }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Container background={testimonials_background_color}>
      <Box width="100%" pb={isXs ? 5 : 12} pt={isXs ? 5 : 12}>
        <Carousel>
          {testimonials &&
            testimonials.map(testimonial => (
              <Box
                px={isXs ? 5 : 3}
                key={testimonial && testimonial.title}
                className={classes.carouselOutline}
              >
                <Typography variant="h6" className={classes.testimonialTitle} align="center">
                  {testimonial && testimonial.title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.testimonialSubtitle}
                  align="center"
                >
                  {testimonial && testimonial.subtitle}
                </Typography>
                <Typography variant="subtitle2" className={classes.testimonialUser} align="center">
                  <img
                    src={testimonial && testimonial.avatar && testimonial.avatar.url}
                    alt="Avatar"
                    className={classes.testimonialAvatar}
                  />
                  {testimonial && testimonial.name}{' '}
                  {testimonial &&
                    testimonial.subscription_plan &&
                    `| ${testimonial.subscription_plan}`}
                </Typography>
              </Box>
            ))}
        </Carousel>
      </Box>
    </Container>
  );
};

AboutTestimonials.propTypes = {
  testimonials_background_color: PropTypes.string,
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired,
      name: PropTypes.string.isRequired,
      subscription_plan: PropTypes.string
    })
  ).isRequired
};
AboutTestimonials.defaultProps = {
  testimonials_background_color: undefined
};
export default AboutTestimonials;
